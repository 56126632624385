<template>
    <div class="following-list">
        <div class="container main-content">
            <div class="row no-gutters following-list-header">
                <div class="col-auto following-list-back">
                    <div class="back-button" @click="$emit('hide-user-following')">
                        <img src="@assets/img/icons/left-chevron.svg">
                    </div>
                </div>
                <div class="col following-list-title">
                    <h1>Following</h1>
                </div>
            </div>
            <div class="following-categories">
                <div class="links">
                    <template v-for="(category, index) in followingCategories">
                        <button
                            :key="index"
                            class="link btn body-text-sb"
                            :class="{ 'is-active' : currentFollowingCategory == category.name }"
                            @click="currentFollowingCategory = category.name"
                        >
                            {{ category.label }}
                        </button>
                    </template>
                </div>
            </div>
            <keep-alive>
                <component :is="currentFollowingCategory" use-api-v2 :list-endpoint="currentFollowingCategoryData.endpoint" />
            </keep-alive>
        </div>
    </div>
</template>

<script>
export default {
    name: "FollowingList",
    components: {
        Creators: () => import(/* webpackChunkName: "creators-list" */ "@/components/organisms/people-list.vue"),
        Boards: () => import(/* webpackChunkName: "boards-list" */ "@/components/organisms/boards-list.vue"),
        Topics: () => import(/* webpackChunkName: "topics-list" */ "@/components/organisms/topics-list.vue")
    },
    props: {
        userId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentFollowingCategory: "creators",
            followingCategories: [
                {
                    name: "creators",
                    label: "Creators",
                    endpoint: `/users/${this.userId}/following?&format=true`
                },
                {
                    name: "boards",
                    label: "Boards",
                    endpoint: `/users/${this.userId}/follows/boards?`
                },
                {
                    name: "topics",
                    label: "Topics",
                    endpoint: `/users/${this.userId}/follows/topics?`
                }
            ]
        };
    },
    computed: {
        currentFollowingCategoryData() {
            return this.followingCategories.find(category => category.name == this.currentFollowingCategory);
        }
    }
}
</script>

<style lang="scss" scoped>
.following-list {
    .main-content {
        padding-top: 100px;
        margin-bottom: 120px;

        .back-button {
            margin-right: 40px;
            width: 40px;
            height: 40px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 10px;
            }
        }

        h1 {
            padding-top: 7px;
            margin-bottom: 15px;
        }

        .following-categories {
            display: flex;
            justify-content: space-between;

            .links {
                display: flex;
                padding-top: 25px;
                padding-bottom: 25px;
                margin-left: auto;
                margin-right: auto;
                .link {
                    display: block;
                    margin-left: 65px;
                    cursor: pointer;
                    padding: 6px 20px;
                    border-radius: 100px;
                    color: white;
                    text-decoration: none;
                    @media(max-width: $lg) {
                        margin-left: 0;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                    &.is-active {
                        background-color: $primary-color;
                    }
                }
            }
        }


        @media(max-width: $md) {
            padding-top: 20px;

            .back-button {
                margin-right: 40px;
            }

            h1 {
                padding-top: 7px;
                margin-bottom: 15px;
            }

            .following-list-header {
                position: relative;

                .following-list-back {
                    order: 1;
                    flex-basis: 100%;
                }

                .following-list-title {
                    order: 3;

                    h1 {
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
